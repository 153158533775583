import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const TeamImage = () => {

    const { lang, translate, folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="broodjes">
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Convergence&display=swap');
            </style>
            <h3>Versbelegde broodjes</h3>
            <h5>Wit of bruin broodje, saus naar keuze, extra garnituur: €0,50</h5>
            <div className='container'>
                <aside>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Ham</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.09</span>
                        </div>
                        <p>Ham, sla, tomaat, wortel, ei, mayonaise</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gehakt</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.09</span>
                        </div>
                        <p>Gehakt (varken-rund), sla, tomaat, wortel, ei, mayonaise</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Kaas (Veggie)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.09</span>
                        </div>
                        <p>Noord-Hollandse jonge gouda, sla, tomaat, wortel, ei, mayonaise</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Salami</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.09</span>
                        </div>
                        <p>Salami, sla, tomaat, wortel, ei, mayonaise</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Kipfilet</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.09</span>
                        </div>
                        <p>Kipfilet, sla, tomaat, wortel, ei, mayonaise</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Veggie</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.09</span>
                        </div>
                        <p>Wortel-seldersalade, tomaat, wortel, ei, rucola</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Boulette</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.09</span>
                        </div>
                        <p>Boulette, ajuin, samoeraisaus</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Preparé</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.19</span>
                        </div>
                        <p>Huisbereide preparé, sla, tomaat, wortel, ei</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Martino</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.19</span>
                        </div>
                        <p>Huisbereide preparé, sla, tomaat, augurk, zilverui, martinosaus, ansjovis</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Ham & kaas</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.19</span>
                        </div>
                        <p>Ham, Noord-Hollandse jonge gouda, sla, tomaat, wortel, ei, mayonaise</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Kip curry</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.19</span>
                        </div>
                        <p>Kip curry, sla, tomaat, wortel, ei</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                </aside>
                <aside>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Tonijnsalade</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.19</span>
                        </div>
                        <p>Tonijnsalade, sla, tomaat, wortel, ei</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Tonijntino</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.39</span>
                        </div>
                        <p>Tonijnsalade, sla, tomaat, augurk, zilverui, martinosaus, ansjovis</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Krabsalade</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.39</span>
                        </div>
                        <p>Krabsalade, sla, tomaat, wortel, ei</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Hawaï</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.39</span>
                        </div>
                        <p>Ham, Noord-Hollandse jonge gouda, sla, tomaat, ananas, cocktailsaus</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Maison</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.39</span>
                        </div>
                        <p>Ham, Noord-Hollandse jonge gouda, salami, tomaat, augurk, ei, cocktailsaus</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Mozzarella (Veggie)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.59</span>
                        </div>
                        <p>Mozzarella, tomaat, pesto, rucola</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Italiano</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€5.39</span>
                        </div>
                        <p>Italiaanse ham, mozzarella, zongedroogde tomaat, pesto, rucola</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Americano</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€5.39</span>
                        </div>
                        <p>Americain, zongedroogde tomaten, pesto, rucola, gerijpte Italiaanse kaas</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Zalmsalade van het huis</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€5.59</span>
                        </div>
                        <p>Zalmsalade van het huis, sla, tomaat, wortel, ei</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gerookte zalm</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>Dagprijs</span>
                        </div>
                        <p>Gerookte zalm, kruidenkaas, tomaat, ajuin</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Grijze garnaal</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>Dagprijs</span>
                        </div>
                        <p>Grijze garnaalsalade, sla, tomaat, ei</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                </aside>
            </div>
            <div className='info'>
                <h4>Info</h4>
                <p>
                    Gelieve vanaf 10 broodjes op voorhand te bestellen. <br />
                    <b>Tel. 058 23 42 34</b>
                </p>

                {/* <h4>Ontdek ook onze salades en dagschotels in de winkel!</h4> */}
                <h4>Om krokant stokbrood te garanderen, bakken we meermaals per dag ter plaatse af.</h4>
            </div>
        </section>
    )
}

export default TeamImage