import { Fragment } from "react";
import { useSelector } from "react-redux";

const VacatureSuperplanner = (props) => {
  const { modules, lang, folder_name } = useSelector((state) => state.AdminReducer);
  return (
    <section id="vacatureSuperplanner">
      <div className="content">
        <h2>Vacatures</h2>
        <p>Al onze openstaande vacatures kan je hieronder terugvinden. Is dit wat je zoekt? mail dan snel naar <a href="mailto:kimberly.vcz@ad-nederename.be">kimberly.vcz@ad-nederename.be</a></p>
        {/* <a className="figure" href="./superplanner?lang=nl"> */}
        {/* <a className="figure" href="https://ad-delhaize-nederename.be/static/media/Vacature.pdf"> */}
        <div className="images">
          
          {/* PDF */}
          <a className="figure" href={require('../library/Vacature.pdf').default} target="_blank">
            <img src={`https://o-sn.be/kml/test-onepages/${folder_name}/vacature.png`} alt="vacature image" />
            <h3>bakkerijmedewerker</h3>
          </a>

          {/* Superplanner */}
          <a className="figure" href={`./superplanner?lang=${lang}`}>
            <img src={`https://o-sn.be/kml/test-onepages/${folder_name}/vacature-1.png`} alt="vacature image" />
            <h3><b>! <u>Hogeschool</u> !</b>&nbsp;studenten en flexi's</h3>
          </a>

          <a className="figure" href={require('../library/Vacature-wijn.pdf').default} target="_blank">
            <img src={`https://o-sn.be/kml/test-onepages/${folder_name}/vacature-2.png`} alt="vacature image" />
            <h3>Verantwoordelijke wijnafdeling</h3>
          </a>

          <a className="figure" href={require('../library/Vacature-vlinder.pdf').default} target="_blank">
            <img src={`https://o-sn.be/kml/test-onepages/${folder_name}/vacature-4.png`} alt="vacature image" />
            <h3>Vlinder</h3>
          </a>

        </div>
      </div>
    </section>
  );
};

export default VacatureSuperplanner;
